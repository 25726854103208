.materials-fieldset {
  margin-top: 25px;
  &:first-of-type {
    margin-top: 0;
  }
  &[disabled] {
    > div {
      > label {
        color: #989795;
        input,
        span::before {
          color: #333 !important;
          cursor: pointer !important;
          color: inherit !important;
        }
      }
    }
  }

  &.no-materials-ordered-fieldset {
    font-weight: bold;
    > p {
      padding-top: 15px;
      border-top: 1px solid rgb(50, 50, 50);
    }
    label {
      font-weight: bold;
    }
  }

  > div {
    &:first-child {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 16px;
      > label {
        font-family: 'Roboto Slab', sans-serif;
        font-size: 16px;
        margin-bottom: 15px;
        font-weight: bold;
        font-weight: bold;
        cursor: pointer !important;
        color: inherit !important;
      }
    }
  }
}

.materials-radiogroup,
.materials-flexgrouping {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  .material-radiogroup-wrap {
    flex-basis: 30%;
    flex-grow: 1;
  }
  .material-radiogroup-wrap-extended {
    flex-basis: 60%;
    flex-grow: 1;
  }
  .material-radiogroup-wrap-full {
    flex-basis: 100%;
    flex-grow: 1;
  }

  input[type='text'] {
    height: 48px;

    + i {
      top: 8px;
    }
  }
}
