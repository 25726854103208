:global(#root) {
	min-height: 600px;
}

:global([class*='col-']) {
	word-break: break-word;
}

:global([class*='cb-loader']) {
	word-break: keep-all;
}

:global(.input-group .form-control) {
	height: 47px;
}

:global(.cb-table-select.cb-table-select-radio .radio input:checked + span::before) {
	color: white;
}

:global(.modal),
:global(.dropdown-menu) {
	z-index: 9999;
}
:global(.modal-backdrop) {
	z-index: 9901;
}

:global(.tooltip) {
	z-index: 11000;
}

:global(.modal-condensed-buttons .modal-footer .btn) {
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: 5px;
}

:global(.warning-text) {
	color: rgb(173, 87, 2);
}
:global(.warning-text.cb-glyph-circular:after) {
	background-color: rgb(173, 87, 2);
}
:global(.success-text) {
	color: rgb(22, 87, 44);
}

:global(.modal-body) {
	max-height: calc(90vh - 212px);
	overflow-y: auto;
}

:global(.modal-body .form-group:last-child) {
	margin-bottom: 24px;
}

:global(.btn-link[disabled]),
:global(a.disabled) {
	opacity: 0.8;
	color: #777;
	cursor: default;
}
:global(.btn-link) {
	text-align: left;
}

:global(.cb-local-navigation .dropdown-toggle) {
	color: #1e1e1e !important;
}
:global(.dropdown-portal ul) {
	width: auto !important;
}

:global(.cb-white-color) a {
	color: #fff !important;
	text-decoration: underline;
}
:global(.cb-white-color) a:hover,
:global(.cb-white-color) a:focus {
	text-decoration: none;
}

:global(.btn-link[disabled]:hover),
:global(a.disabled:hover),
:global(.btn-link[disabled]:focus),
:global(a.disabled:focus) {
	color: #777 !important;
	text-decoration: none !important;
	border-bottom: none !important;
	opacity: 1 !important;
	cursor: not-allowed;
}

:global(input[type='text'][disabled]) {
	color: #989795;
	opacity: 0.67;
}

:global(.cb-glyph-right) {
	margin-left: 7px;
}

:global(.table .dropdown a) {
	text-decoration: none !important;
}

:global(.lv-identity-widget.lv-logged-in) {
	display: none;
}

:global(h1.cb-site-name) {
	font-size: 16px;
	line-height: 1em;
	margin: 0;
}

:global(.cb-accordion .panel .panel-body *) {
	font-size: inherit;
}
:global(.cb-accordion .panel .panel-body .h3),
:global(.cb-accordion .panel .panel-body h3) {
	font-size: 1.1875rem;
}

:global(.skipwrapper) {
	width: 1px;
	height: 1px;
	overflow: hidden;
	float: right;
}

:global(.tabbable-content-only) {
	position: relative;
}
:global(.tabbable-content-only button) {
	position: absolute;
	left: -1000px;
}
:global(.tabbable-content-only button:focus) {
	position: relative;
	border: 2px solid #ccc;
	left: 0px;
}

/** CSSTransitions **/

:global(.animate-fade-appear) {
	opacity: 0.01;
}

:global(.animate-fade-appear.animate-fade-appear-active) {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

:global(.animate-slide-appear) {
	max-height: 0;
	overflow: visible;
	-webkit-transition: max-height 0.5s linear;
	-moz-transition: max-height 0.5s linear;
	-ms-transition: max-height 0.5s linear;
	-o-transition: max-height 0.5s linear;
	transition: max-height 0.5s linear;
}

:global(.animate-slide-appear.animate-slide-appear-active) {
	max-height: 700px;
	overflow: hidden;
}

/** /CSSTransitions **/

/** MOBILE BREAKPOINT **/
:global(.visible-xxs) {
	display: none !important;
}
@media (max-width: 374px) {
	:global(.visible-xxs) {
		display: block !important;
	}
	:global(.hidden-xxs) {
		display: none !important;
	}
	:global(.col-xxs-12) {
		float: none;
		width: 100%;
		margin-bottom: 10px;
	}
}

/** /MOBILE BREAKPOINT **/

/** Fix for broken Apricot style on form error **/
.form-group.has-error .input-group-addon i {
	position: static;
	top: auto;
	right: auto;
	text-align: center;
}
.form-group.has-error .input-group i {
	z-index: 100;
}

/** Fix for broken Select controls when embedded within a table **/
#root .table > thead > tr > th .cb-select span,
#root .table > tbody > tr > th .cb-select span,
#root .table > thead > tr > td .cb-select span,
#root .table > tbody > tr > td .cb-select span {
	line-height: 3em !important;
}

@media print {
	@page {
		margin: 0;
		size: A4 portrait;
	}

	cbw-header,
	cbw-footer,
	.cb-local-navigation,
	.secondary-navigation {
		display: none !important;
	}

	body {
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
		position: relative;
	}

	a[href] {
		text-decoration: none !important;
	}
	a[href]:after {
		content: none !important;
	}

	.print-only {
		display: block !important;
	}

	.no-print {
		display: none !important;
	}
}

.print-only {
	display: none;
}

/** icons **/
.alert-icon {
	position: relative;
}
.alert-icon i {
	display: inline-block !important;
	height: 1.05em !important;
	width: 1.05em !important;
	position: absolute;
	top: 3px;
}
.alert-icon i:before {
	left: 3px !important;
	top: 3px !important;
	font-size: 0.7em;
	font-weight: bold;
}
.alert-icon i:after {
	background: #e57200 !important;
	height: 1.05em !important;
	width: 1.05em !important;
}
.alert-icon-warning i:after {
	background: #999999 !important;
}

.alert.cb-alert-heading.alert-warning.notification.no-title p:first-of-type {
	position: relative;
}

.alert.cb-alert-heading.alert-warning.notification h2:before,
.alert.cb-alert-heading.alert-warning.notification h3:before,
.alert.cb-alert-heading.alert-warning.notification h4:before,
.alert.cb-alert-heading.alert-warning.notification.no-title p:first-of-type:before {
	speak: none;
	font-family: 'CB Icons';
	font-style: normal;
	font-weight: 700;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 26px;
	content: 'i';
	color: #fff;
	position: absolute;
	z-index: 2;
	left: 12px !important;
	top: 1px !important;
}

.alert.cb-alert-heading.alert-warning.notification h2:after,
.alert.cb-alert-heading.alert-warning.notification h3:after,
.alert.cb-alert-heading.alert-warning.notification h4:after,
.alert.cb-alert-heading.alert-warning.notification.no-title p:first-of-type:after {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: block;
	border-radius: 50%;
	height: 32px;
	width: 32px;
	background-color: rgb(0, 119, 200);
}

.alert.cb-alert-heading.notification p:last-of-type {
	margin-bottom: 0 !important;
}

@media print {
	.cb-dropdown .dropdown-toggle .cb-glyph {
		display: none;
	}
}
