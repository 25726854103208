$border_color: #d9d9d9;

:global {
	.ReactVirtualized__Table__row {
		overflow: visible !important;

		&:last-child {
			border-bottom: 1px solid $border_color;
		}
	}

	.ReactVirtualized__Table__headerRow {
		text-transform: capitalize !important;
		color: #f0f0f0;
		background: #505050;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
	}

	.ReactVirtualized__Table__rowColumn,
	.ReactVirtualized__Table__headerColumn {
		font-size: 1rem;
		line-height: 1.5em;
		margin: 0 !important;
		padding: 11px 10px;
		border-left: 1px solid $border_color;
		height: inherit;
		border-top: 1px solid $border_color;

		&:last-child {
			border-right: 1px solid $border_color;
		}
	}

	.ReactVirtualized__Table__sortableHeaderColumn {
		position: relative;

		:global(.sortable-header) {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&:focus {
			outline: 1px solid black;
			outline-offset: -2px;
		}

		&:hover,
		&:focus {
			text-decoration: underline;

			i {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	.ReactVirtualized__Table__row {
		.dropdown {
			.dropdown-toggle {
				width: 100%;
				display: inline-block;

				> i {
					position: absolute;
					right: 0;
					top: 6px;
				}
			}
		}
	}
}

.infinite-scroll-table-responsive {
	&.tablet,
	&.mobile {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		overflow-x: auto;
		border: 1px solid #cccac8;
	}

	.inactive-row {
		background: rgb(240, 240, 240);
		font-style: italic;
	}
	.inactive-border-top {
		border-top: 1px solid rgb(240, 240, 240);
	}
}
