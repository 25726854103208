table.exam-registrations-table {
	td.exam-enrolled,
	td.exam-reduced-fee,
	td.exam-ssd,
	td.exam-total-exams,
	td.exam-total-cost,
	td.exam-not-taking,
	td.exam-uncollected {
		text-align: right;
	}

	td.exam-date {
		> span {
			&:first-of-type {
				padding-left: 5px;
			}
			&:last-of-type {
				float: right;
			}
		}
	}

	td.exam-total-exams {
		> div {
			> sup {
				font-size: 0.9em;
				padding-left: 5px;

				a {
					color: #c13145;
				}
			}
		}
	}
}

.order-details-cont {
	> div {
		padding-bottom: 20px;
		font-size: 14px;
	}

	h2 {
		border-bottom: 2px solid #ddd;
		padding-bottom: 15px;
		margin-bottom: 15px;

		&.restricted {
			border-color: #e57200;
		}
	}

	.ship-to-cont {
		address {
			margin-bottom: 0;
		}
	}
}

.orders-submit-cont {
	.order-details-link {
		font-size: 1.15em;
		line-height: normal;
	}

	.order-details-dates-cont {
		> div {
			line-height: normal;
			text-align: right;
		}
	}
	.order-callout-box {
		margin-top: 20px;
		background: #e9f8ff;
		border: 1px solid #d3ebf6;
		border-radius: 5px;
		padding: 15px 20px;

		h3,
		p {
			color: #47748b;
			margin-bottom: 10px;
		}

		a {
			color: #0071bd;
			font-weight: bold;
		}
	}
}

.order-border-warning {
	border: 2px solid #e57200;
}

.order-submit-button {
	width: 100%;
}
