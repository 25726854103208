.incident-date {
  display: inline-block;
  width: 200px;
}

.incident-count {
  display: inline-block;
  width: 300px;
}

.incident-submitter {
  display: inline-block;
}

.report-content {
  padding-bottom: 24px;
  border-bottom: 2px solid #555;
  margin-bottom: 24px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .report-heading {
    display: flex;

    .report-id {
      margin: auto 0;
      font-weight: bold;
    }

    .print-button {
      margin-left: auto;
      @media print {
        display: none;
      }
    }
  }

  .report-body {
    padding-bottom: 24px;

    .report-section {
      margin-top: 24px;

      h4 {
        font-family: 'Roboto Slab Bold', sans-serif;
        font-size: 1.1875rem;
        line-height: 1.26315789em;
        margin: 0 0 24px 0;
      }

      h5 {
        font-family: 'Roboto Bold', sans-serif;
      }

      .field-name {
        display: inline;
        font-weight: bold;

        & + .field-value {
          display: inline-block;
          margin-left: 8px;
        }
      }

      ul {
        padding-left: 20px;

        li {
          list-style: disc;

          ul {
            li {
              list-style: circle;
            }
          }
        }
      }

      p.field-value {
        margin-bottom: 0 !important;
      }

      .incident-details {
        white-space: pre-wrap;
        height: 250px;
        overflow: auto;
        border: 1px solid #ccc;
        padding: 16px;
        @media print {
          height: auto;
          border: none;
          padding: 0;
        }
      }
    }
  }
}
