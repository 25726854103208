.tac-container {
  height: 400px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  margin-bottom: 20px;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    height: auto;
    border: none;
    padding: 0;
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 8px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }

  h4 {
    font-weight: normal;
    text-decoration: underline;
    font-family: Roboto, sans-serif;
  }

  ol {
    counter-reset: item;
    padding-bottom: 10px;

    > li {
      display: block;
      padding-bottom: 10px;

      &:before {
        content: counters(item, ".") " ";
        counter-increment: item;
      }
    }

    &.unstyled li {
      padding-bottom: 0;
    }
  }

  > ol > li {
    &:before {
      content: counters(item, ".") ". ";
      font-weight:bold;
    }
  }

  ol li ol {
    padding-top: 5px;

    li ol {
      li:before {
        content: counter(item, lower-alpha) ") ";
      }
      ol li:before {
        content: counter(item, lower-roman) ") ";
      }
    }
  }

  ul li {
    margin-left:24px;
    padding-bottom: 10px;
  }

}


.tac-controls {
  text-align: right;
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

.tac-check {
  text-align: right;
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

.tac-submit {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}