$successColor: rgb(255, 255, 255);
$successBgColor: rgb(92, 184, 92);
$primaryColor: rgb(255, 255, 255);
$primaryBgColor: rgb(51, 122, 183);
$infoColor: rgb(255, 255, 255);
$infoBgColor: rgb(91, 192, 222);
$warningColor: rgb(255, 255, 255);
$warningBgColor: rgb(240, 173, 78);
$dangerColor: rgb(255, 255, 255);
$dangerBgColor: rgb(217, 83, 79);
$defaultColor: rgb(80, 80, 80);
$defaultBgColor: rgb(240, 240, 240);

%full-size-label {
  display: inline;
  padding: 3px 4px;
  font-size: 85%;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  color: $defaultColor;
  background-color: $defaultBgColor;
}

.label {
  @extend %full-size-label;
}
.label-success,
.label-status-approved {
  @extend %full-size-label;
  color: $successColor;
  background-color: $successBgColor;
}
.label-primary,
.label-status-new {
  @extend %full-size-label;
  color: $primaryColor;
  background-color: $primaryBgColor;
}
.label-warning,
.label-status-needs-info {
  @extend %full-size-label;
  color: $warningColor;
  background-color: $warningBgColor;
}
.label-danger,
.label-status-denied {
  @extend %full-size-label;
  color: $dangerColor;
  background-color: $dangerBgColor;
}
.label-default {
  @extend %full-size-label;
  color: $defaultColor;
  background-color: $defaultBgColor;
}

%dot-size-status-label {
  display: inline;
  white-space: nowrap;
  span.dot {
    padding-right: 4px;
    font-size: .7em;
  }
}

.status-dot-not-submitted {
  @extend %dot-size-status-label;
  span.dot {
    color: $defaultColor;
  }
}
.status-dot-new {
  @extend %dot-size-status-label;
  span.dot {
    color: $primaryBgColor;
  }
}
.status-dot-approved {
  @extend %dot-size-status-label;
  span.dot {
    color: $successBgColor;
  }
}
.status-dot-needs-info {
  @extend %dot-size-status-label;
  span.dot {
    color: $warningBgColor;
  }
}
.status-dot-denied {
  @extend %dot-size-status-label;
  span.dot {
    color: $dangerBgColor;
  }
}
