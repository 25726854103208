@import "variables";
@import "mixins";

html {
  font-size: 16px;
}

.react-datepicker-popper {
  min-width: 325px;
  z-index: 100;
}
.react-datepicker-container {
  position: relative;

  &.disabled {
    opacity: .65;
  }

  .form-group {
    input {
      padding-right: 25px;
      padding-left: 10px;
    }
  }
  .react-datepicker-wrapper {
    position: absolute;
    right: 3px;
    top: 13px;
    .ui-datepicker-trigger {
      margin: 0;
      width: auto !important;
      height: auto !important;
      line-height: normal;
      padding-bottom: 5px;
      border: 1px solid transparent;

      &:focus {
        border:1px solid;
      }
    }
  }
}

.react-datepicker {
  font-family: HelveticaNeue,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  display: inline-block;
  position: relative;
}

.react-datepicker__wrapper {

  &.disabled {
    opacity: .5;
  }
  
  .ui-datepicker-trigger {
    position: absolute;
    right: 40px;
    top: 33px;
  }
  .has-error .ui-datepicker-trigger {
    display: none;
  }
}

.has-error .ui-datepicker-trigger {
  display: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  @extend %triangle-arrow-up;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle {
  @extend %triangle-arrow-down;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
  margin-top:-3px;
}

.react-datepicker__tether-element-attached-top.react-datepicker__tether-element {
  margin-top:-10px;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: $datepicker__text-color;
  font-weight: bold;
  font-size: $datepicker__font-size * 1.18;
  padding-bottom: 10px;
}

.react-datepicker__navigation {
  border: none;
  padding: 0;
  height: 20px;
  width: 15px;
  overflow:hidden;
  position: absolute;
  display: block;
  background: 0 0;
  font-family: 'CB Icons';
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  color: #505050;
  line-height: 25px;
  top: 8px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  &--previous {
    left: 10px;
    border-right-color: $datepicker__muted-color;

    &:before {
      font-size: 16px;
      content: "\e921";
    }
  }

  &--next {
    right: 10px;
    border-left-color: $datepicker__muted-color;

    &:before {
      font-size: 16px;
      content: "\e940";
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
      border-top-color: $datepicker__muted-color;

      &:hover {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $datepicker__muted-color;

      &:hover {
        border-bottom-color: darken($datepicker__muted-color, 10%);
      }
    }
  }
}

.react-datepicker__month-container {
  display: inline;
  float: left;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.react-datepicker__week {
  border-top: 1px solid $datepicker__border-color;
  background-color: #ecebe8;
  .react-datepicker__day {
    border-right: 1px solid $datepicker__border-color;

    &:not(.react-datepicker__day--disabled) {
      background-color: #fff;
    }
    
    &:last-of-type {
      border-right: 0 none;
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__day-names {
  border-top: 1px solid $datepicker__border-color;
  .react-datepicker__day-name {
    font-weight: bold;
    line-height: 20px;
  }
}

.react-datepicker__day {
  cursor: pointer;
  border:1px solid transparent;

  &:hover {
    background-color: $datepicker__background-color !important;
    color: #fff;
    text-decoration: underline;
  }

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    background-color: $datepicker__highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    background-color: $datepicker__selected-color !important;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%) !important;
    }
  }

  &--keyboard-selected {
    background-color: lighten($datepicker__selected-color, 10%) !important;
    color: #fff;
    text-decoration: underline;
    border:2px dashed $datepicker__selected-color !important;
    font-weight: 700;
    margin-top: -2px;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%) !important;

    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, .5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
      color: $datepicker__muted-color;
    }
  }
  &--outside-month {
    background-color: $datepicker__background-color-outside;
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $datepicker__muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $datepicker__navigation-size;
  }

}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;

  &::after {
    background-color: $datepicker__selected-color;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__tether-element {
  z-index: 2147483647;
}

