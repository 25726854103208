@import '~@myap/ui-library/sass/queries-mixins.scss';

.time-breakdown {
  margin-bottom: 2em;

  &:not(:first-child) {
    h5 {
      margin-top: 2em;
    }
  }
  h5 {
    font-family: 'Roboto', sans-serif;
    margin-bottom: .5em;
  }
}

.course-breakdown,
.rooms-container {
  border: 1px solid #ccc;
  padding-left: 15px;
  padding-right: 15px;

  :global(.row) {
    > div:last-child {
      text-align: right;

      @include mobile {
        margin-top: 1em;
        text-align: left;
      }
    }
  }
}

.course-breakdown {
  background: #e9e9e9;
  padding-top: 10px;
  padding-bottom: 10px;

  h6 {
    font-weight: 700;
    font-size: 1.2em;
    margin-top: 10px;
  }

  button {
    background: #fff;
    margin-bottom: 0;
  }

  + .course-breakdown {
    margin-top: 1.5em;
  }
}

.rooms-container {
  border-width: 0 1px 1px 1px;
  margin-bottom: 1em;

  :global(.row) {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    border-bottom: 1px solid #ccc;

    &:last-of-type {
      border-bottom-width: 0;
    }
  }

  a {
    > i {
      font-size: .8em !important;
    }
  }
}

.room-progress {
  font-size: .9em !important;
  line-height: 2em;
  margin-top: 5px;

  :global(.progress) {
    margin-bottom: 0;
    background-color: rgb(214, 214, 214);
    border-radius: 2px;
  }
}

.exam-room-details {
  margin-bottom: 3em;

  :global(.row) {
    margin-bottom: 1.5em;

    &:first-child {
      > div:last-child {
        text-align: right;
        margin-top: 3px;

        @include mobile {
          margin-top: 0;
          text-align: left;
        }  

        button {
          font-family: 'Roboto', sans-serif;
          margin-left: 20px;
          padding: 0;

          @include mobile {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.exam-room-students {
  h3 {
    margin-bottom: .5em;
  }

  :global(.row) {
    &:first-of-type {
      margin-bottom: .5em;

      button {
        font-family: 'Roboto', sans-serif;
        padding: 0;
        margin-left: 20px;
        border: 0;

        @include mobile {
          display: block;
          margin-left: 0;
          padding: 5px 0;
        }
      }

      > div:last-child {
        text-align: right;

        @include mobile {
          text-align: left;
        }
      }
    }
  }

  .exam-room-students-empty {
    text-align: center;
    font-size: 1.2em;
    margin-top: 2.5em;
    margin-bottom: 3em;
    font-family: 'Roboto Slab Bold', sans-serif;

    button {
      font-size: inherit;
    }
  }
}

.btn-delete {
  float: left;
  color: #c13145;
  border-color: #c13145;
}

.method-modal {

  label {
    font-weight: 700;
  }
  p:not(:first-child) {
    margin-left: 26px;
  }
}

.manual-assignment-modal {
  min-height: 350px;

  .warning-icon {
    color: #c13145;
    padding-left: 30px;
    line-height: 25px;
    position: relative;
    &::before {
      font-family: "CB Icons";
      z-index: 2;
      position: absolute;
      top: 0;
      left: 6px;
      font-size: .9em;
      content: "\e919";
    }
    &::after {
      border-radius: 50%;
      border: 2px solid #c13145;
      width: 25px;
      height: 25px;
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      content: "";
    }
  }
  .assignable-table-container {
    max-height: 300px;
    margin-bottom: 1em;
    overflow-y: scroll;
    position: relative;
    table {
      td, th {
        border-left: 0 !important;
        border-right: none;

        > div {
          font-size: .9em;
          line-height: normal;
        }

        &:first-child,
        &:last-child {
          width: 50px !important;
          min-width: 50px !important;
        }

        &:last-child {
          text-align: right;
        }
      }

      tbody tr {
        background: #f9f9f9;

        &:first-child {
          background: white;
        }
      }
    }
  }
}

@media print {
  .exam-room-details {
    margin-top: 1em;
  }
  
  .exam-room-action-bar {
    display: none;
  }

  .exam-room-students {
    margin-top: 2em;

    table {
      margin-top: 1.5em;

      th, td {
        border: 1px solid #d9d9d9 !important;
      }

      th:first-child,
      td:first-child {
        display: none;
      }

      :global(.cb-glyph) {
        display: none;
      }
    }
  }
}