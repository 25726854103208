.select-schools {
  margin-bottom: 24px;

  .select-label {
    font-weight: bold;
  }
  .select-schools-controls {
    width: 100%;
    height: 512px;
    padding: 5px;

    .school-option {
      padding: 5px;
      border: 0;
    }
  }
  .move-school-button {
    width: 100%;
    margin: 0;
    padding: 12px 15px;
  }
}

.section-heading {
  display: inline-block;
  margin-bottom: 12px;
}

.section {
  dt {
    display: block;
    margin-left: 0;
    font-weight: bold;
  }
  dd {
    display: block;
    margin-left: 0;
    margin-bottom: 12px;
  }

  .schools {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li:last-child {
        margin-bottom: 12px;
      }
    }
  }
}

.intro {
  &.no-state-funding,
  &.alert {
    padding: 16px;
    //background-color: #e57200;
    //color: #fff;
    border-radius: 5px;
    margin-bottom: 24px;
    border: 8px solid #e57200;

    p {
      font-size: 1.3rem;
    }

    //a {
    //  text-decoration: underline;
    //  color: #fff;
    //}

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.subsidy-configuration {
  .fee-type {
    margin-bottom: 24px;
    .header {
      font-size: 1.4em;
      font-weight: bold;
      margin-bottom: 12px;
    }
    .content {
      margin-left: 40px;
      .exam-type {
        .header {
          font-size: 1.1em;
          font-weight: bold;
          margin-bottom: 8px;
          font-family: 'Roboto Bold';
        }
        .content {
          margin-left: 40px;
          .pricing {
            margin-bottom: 12px;
            .pay-remainder {
              display: inline-block;
              margin-left: 12px;
            }
            .form-group {
              margin-bottom: 8px;
              &.has-error {
                margin-bottom: 24px;
              }
            }
          }
          .pricing-total {
            font-weight: bold;
            //border-top: 1px solid #000;
            width: 300px;
            //padding-top: 8px;
            margin-bottom: 12px;
          }
          .other-fees {
            margin-bottom: 24px;
            button {
              padding: 0;
              margin: 0;
              border-width: 0;
            }
          }
          .other-fees-container {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
