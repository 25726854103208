.align-menu-left {
  float: left;
  color: #000;
  margin-right: 20px;
  font-family: 'Roboto Slab', sans-serif;
  @media print {
    float: none;
    display: inline-block;
  }
}

.align-menu-right {
  float: right;
  color: #000;
  font-family: 'Roboto Slab', sans-serif;
}
