.fees-status-and-cost {
  margin-bottom: 2em;
  > div {
    margin-bottom: .5em;
  }
  label {
    font-weight:bold;
  }
}

.section-panel {
  font-size:1em;

  &.has-indicator {
    h4 {
      font-style: italic;
    }
  }

  label, h5 {
    font-weight: bold;
    font-family: inherit;
  }

  .description {
    margin-bottom:1em;
  }

  .taking-exam-container {
    label {
      margin-bottom: 0;
    }
  }

  .exam-date-container {
    label {
      margin-bottom: 0;
    }
  }

  .cost {
    dl {
      dd { 
        margin-left: 0;
        float: right;
      }
      dt {
        clear: left;
        margin-left: 0;
        float: left;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
      }
    }
  }

  .ssd-materials-container {
    h5 {
      position: relative;
      display: inline-block;
    }
    .ssd {
      .selected-ssd-materials {
        font-size: 1em;
      }
    }
  }
}

.student-details-total-cost {
  font-size: 2em;
  letter-spacing: -2px;
  line-height:normal;
}

.total-cost-breakdown {
  border-top: 1px solid #ccc;
  margin-top: 10px;

  > div {
    border-bottom: 1px solid #ccc;
    text-align: right;
    padding: 10px;

    > div:first-child {
      float: left;
      text-align: left;
    }
  }

  .breakdown-total {
    font-weight: bold;
    background: #EAF7FD;
  }
  
}


.ssd-accommodations {
  .status-date-col,
  .received-col {
    white-space: nowrap;
  }
  .status-col {
    border-right: none;

    > div {
      position: relative;

      > span {
        padding-left: 20px;
      }

      > i {
        position: absolute;
        top: 3px;
      }

      .approved {
        color: #3c763d;
      }
      .denied {
        color: #c13145;
      }
    }

  }
  .status-date-col {
    border-left: none;
    padding-left: 0;
    text-align: right;
  }
}

.has-change-order-divider {
  border-bottom: 3px solid rgb(90, 90, 90);
}