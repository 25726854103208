.customer-service-contact {
  dt {
    float: left;
    clear: left;
    margin: 0 5px 0 0;
    font-weight: bold;
  }

  dd {
    margin-left: 0px;
  }
}