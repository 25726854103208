@import '~@myap/ui-library/sass/queries-mixins.scss';

.coordinator-promo {
	padding: 2em;
	margin-bottom: 2em;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		flex-grow: 1;

		&:first-child {
			margin-right: 2em;
		}

		h2 {
			margin-bottom: 0.5em;
		}
	}
}
.coordinator-banner {
	margin-top: -20px;
	padding-top: 3.5em;
	padding-bottom: 4em;

	&.mobile {
		> div {
			display: block;
			> div:first-child {
				margin-bottom: 1em;
				margin-right: 0;
			}
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div {
			&:first-child {
				margin-right: 2em;
				flex-grow: 1;
			}

			&:nth-of-type(2) {
				text-align: center;
			}

			h2 {
				font-size: 2.25em;
				margin-bottom: 0.5em;
			}
		}
	}
}
.coordinator-course-picker {
	> div {
		border: 1px solid #ccc;
		border-radius: 5px;
		width: 100%;
		margin-bottom: 1em;

		label {
			font-weight: 700;
			padding: 1.25em 1.25em 1.25em 2.5em;
			width: inherit;
		}

		&:hover {
			background: #f5f5f5;
		}
	}
}
.coordinator-scoring-materials-picker {
	margin-top: 1em;
	a:global(.dropdown-toggle) {
		color: #fff !important;
		white-space: nowrap;
		font-size: 0.875em !important;
	}
	ul {
		width: 100%;

		a {
			line-height: normal !important;
			padding-top: 5px !important;
			padding-bottom: 5px !important;
			white-space: normal;
		}
	}
}
.makeup-note {
	margin: 1.5em 0 2.5em 0;
	padding: 1.5em;
}

.table-actions-legend {
	margin-bottom: 2em;
	display: flex;
	justify-content: space-between;
	align-content: center;
	flex-wrap: wrap;

	> div {
		align-self: center;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include small-format {
			margin-bottom: 0.5em;
		}

		> *:not(:last-child) {
			margin-right: 20px;
		}

		> span {
			display: flex;
			align-items: center;
			font-weight: 700;
			font-size: 0.875em;

			i {
				margin-right: 5px;
			}
		}
	}

	form {
		display: inline-block;

		> div {
			margin-bottom: 0 !important;
		}
	}
}

.teacher-table-header {
	margin-top: 3em;
	h3,
	a:global(.dropdown-toggle) {
		font-family: 'Roboto Slab Bold', sans-serif !important;
		font-size: 1.5rem !important;
		color: #1e1e1e !important;
		white-space: nowrap;
		align-self: center;
		margin: 0;

		i {
			font-size: 1rem !important;
		}

		&:hover {
			border-bottom-width: 0 !important;
		}
	}
}

// coordinator columns
.exam-date-column {
	vertical-align: middle;
	i {
		font-size: 0.875em;
	}

	strong,
	span {
		display: inline-block;
		text-align: right;
	}
	strong {
		width: 42px;

		& + span {
			width: 53px;
			margin-right: 3px;
		}
	}
}
.student-column {
	overflow: hidden;
	text-overflow: ellipsis;
	a {
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}
}

.teacher-table {
	tbody {
		th {
			font-weight: normal;
		}
	}
	thead {
		th {
			@include desktop {
				:global([data-toggle='tooltip']) {
					padding-left: 0 !important;
					display: block !important;
					padding-top: 0 !important;
				}
			}
		}
	}
}
