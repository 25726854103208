.summary-table {
	width: 100%;
	border: solid #ccc;
	border-width: 1px 0;

	td, th {
		padding: 10px 2px;
		font-weight: 400;
		line-height: normal;
	}

	th {
		font-size:.9em;
	}

	tbody tr:first-of-type th {
		font-weight:bold;
	}

	+ a {
		margin: 8px 0 50px 0;
		display: block;
	}
}

.question-mark-box {
	display: inline-block;
	margin-left: 5px;
	
	> div > span {
		font-weight: 700 !important;
		padding:1px;
		font-size:9px;
		border:1px solid;
		border-radius: 1px;
	}
}

.compare-controls {
	> div {
		display:inline-block;
		margin-right: 10px;
	}

	> span {
		button {
			padding: 0;

			&[disabled] {
				color: #777;
			}
		}
	}
}
.statistics-table {

	tbody {
		th {
			font-weight: 400;
		}
	}
	.special-column {
		text-align: center
	}

	.compare-column {
		text-align: right;
	}
	.stat-column {
		display: none;
		padding-left:0;
	}

	&.compare {
		.compare-column {
			border-right: none;
			padding-right:0;
		}
		.stat-column {
			display: table-cell;
			border-left: none;
		}

		.trend {
			position: relative;
			padding-left: 20px;
			font-size: 12px;
			font-weight: 700;
			line-height:24px;

			> div {
				position: absolute;
			}

			&.trend-up {
				color: #3c763d;
				> div {
	    		transform: rotate(-90deg);
					left: 5px;
					top: -1px;
				}
			}
			&.trend-down {
				color: #c13145;
				> div {
	    		transform: rotate(90deg);			
					left: 2px;
				}
			}
		}
	}
}